import { Component } from '@angular/core';

@Component({
  selector: 'app-kids-story',
  templateUrl: './kids-story.component.html',
  styleUrls: ['./kids-story.component.scss']
})
export class KidsStoryComponent {

}
