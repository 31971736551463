import { Component } from '@angular/core';

@Component({
  selector: 'app-indian-food',
  templateUrl: './indian-food.component.html',
  styleUrls: ['./indian-food.component.scss']
})
export class IndianFoodComponent {

}
