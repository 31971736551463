<app-header-style-four></app-header-style-four>

<!-- <div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Checkout</li>
            </ul>
            <h2>Checkout</h2>
        </div>
    </div>
</div> -->

<div class="checkout-area ptb-100">
    <div class="container">
        <h4 class="mb-3">Check Out</h4>
        <div class="user-actions">
            <!-- <i class='bx bx-log-in'></i> -->
            <!-- <span>Returning customer? <a routerLink="/login">Click here to login</a></span> -->
        </div>
        <form>
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="billing-details">
                        <h3 class="title">Billing Details</h3>
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Country <span class="required">*</span></label>
                                    <div class="select-box">
                                        <select class="form-select" [(ngModel)]="rate"
                                        (change)="rateCal(rate)" [ngModelOptions]="{standalone: true}">
                                            <option value="1">United States</option>
                                            <option value="1.36">Canada</option>
                                            <option value="83.24">India</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>First Name <span class="required">*</span></label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Last Name <span class="required">*</span></label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label>Address <span class="required">*</span></label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label>Town / City <span class="required">*</span></label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>State / County <span class="required">*</span></label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Postcode / Zip <span class="required">*</span></label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Email Address <span class="required">*</span></label>
                                    <input type="email" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Phone <span class="required">*</span></label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="order-details">
                        <h3 class="title">Your Order</h3>
                        <div class="order-table table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Product Name</th>
                                        <th scope="col">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let list of cartList">
                                        <td class="product-name">
                                            <a routerLink="/single-products">{{list.name}}</a>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">{{cur}} {{(list.price*list.qty*rate).toFixed(2)}}</span>
                                        </td>
                                    </tr>
                                    
                                    <tr>
                                        <td class="total-price">
                                            <span>Order Total</span>
                                        </td>
                                        <td class="product-subtotal">
                                            <span class="subtotal-amount">{{cur}} {{(totAmt*rate).toFixed(2)}}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style="float: right;margin-top: 15px;">
                            <a (click)="orderConfirm()" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Place Order</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
