import { Component, OnInit, HostListener, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-header-style-four',
    templateUrl: './header-style-four.component.html',
    styleUrls: ['./header-style-four.component.scss']
})
export class HeaderStyleFourComponent implements OnInit {

    isSticky: boolean = false;
    // cartCount:number=0
    cartList: any;
    @Input() cartCount:number = 0
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }

    constructor(public router:Router) { 
        this.cartList = JSON.parse(localStorage.getItem("item"));
        if(this.cartList)
        {
            this.cartCount = this.cartList.length
        }
        else
        {
            this.cartCount = 0
        }
    }

    ngOnInit(): void {
    }

    refresh(root)
    {
        
        this.router.navigate([root])
        setTimeout(() => {
            location.reload()
          }, 500);
        
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    classApplied2 = false;
    toggleClass2() {
        this.classApplied2 = !this.classApplied2;
    }

    classApplied3 = false;
    toggleClass3() {
        this.classApplied3 = !this.classApplied3;
    }

}