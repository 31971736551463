import { Component } from '@angular/core';

@Component({
  selector: 'app-indian-sweets',
  templateUrl: './indian-sweets.component.html',
  styleUrls: ['./indian-sweets.component.scss']
})
export class IndianSweetsComponent {

}
