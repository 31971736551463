<header class="header-area p-relative">
    
    <div class="navbar-area navbar-style-four" [ngClass]="{'sticky': isSticky}">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
                <a class="navbar-brand" routerLink="/" style="width: 20%;"><img src="assets/img/black-logo.png" alt="logo"></a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Fashion <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a (click)="refresh('/shop-grid/kids - collections')" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Kids Wear</a></li>
                                <li class="nav-item"><a (click)="refresh('/shop-grid/mens - collection')" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Mens Wear</a></li>
                                <li class="nav-item"><a (click)="refresh('/shop-grid/women-collection')" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Womens Wear</a></li>
                            </ul>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Indian Foods <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a (click)="refresh('/shop-grid/product')" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Vadiyalu &amp; Papads</a></li>
                                <li class="nav-item"><a (click)="refresh('/shop-grid/cereals-grains')" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cereals &amp; Grains</a></li>
                                <li class="nav-item"><a href="#" class="nav-link">Spices &amp; Pickles <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a (click)="refresh('/shop-grid/veg-pickles')" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Veg Pickles</a></li>
                                        <li class="nav-item"><a (click)="refresh('/shop-grid/non-veg-pickles')" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Non Veg Pickles</a></li>
                                        <li class="nav-item"><a (click)="refresh('/shop-grid/masalas-spices')" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Masalas &amp; Spices</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a href="#" class="nav-link">Sweets &amp; Namkeen <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a (click)="refresh('/shop-grid/sugar-free-sweets')" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sugar Free Sweets</a></li>
                                        <li class="nav-item"><a (click)="refresh('/shop-grid/indian-snacks')" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Indian Snacks</a></li>
                                        <li class="nav-item"><a (click)="refresh('/shop-grid/indian-sweets')" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Indian Sweets</a></li>
                                    </ul>
                                </li>
                                
                                <li class="nav-item"><a (click)="refresh('/shop-grid/dehydrated-powders')" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Dehydrated Powders</a></li>
                                <li class="nav-item"><a (click)="refresh('/shop-grid/niloufer-biscuits')" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Niloufer Biscuits</a></li>
                                <li class="nav-item"><a (click)="refresh('/shop-grid/childhood-memories')" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Childhood Memories</a></li>
                            </ul>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Indian Heritage <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a (click)="refresh('/shop-grid/groceries')" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Indian Groceries</a></li>
                                <li class="nav-item"><a (click)="refresh('/shop-grid/indian-toys')" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Kondapalli Toys</a></li>
                                <li class="nav-item"><a (click)="refresh('/shop-grid/spiritual-zone')" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pooja Items</a></li>
                            </ul>
                        </li>
                        <li class="nav-item"><a (click)="refresh('/shop-grid/kids-story-books')" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Kids Story Books</a></li>
                        <li class="nav-item"><a (click)="refresh('/shop-grid/home-kitchen')" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home & Kitchen</a></li>
                        
                        <!-- <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li> -->
                    </ul>
                </div>
                <div class="others-option">
                    <!-- <div class="dropdown language-switcher d-inline-block" [class.active]="classApplied2">
                        <button class="dropdown-toggle" type="button" (click)="toggleClass2()">
                            <img src="assets/img/us-flag.jpg" class="shadow" alt="image">
                            <span>Eng <i class='bx bx-chevron-down'></i></span>
                        </button>
                        <div class="dropdown-menu">
                            <a href="#" class="dropdown-item d-flex align-items-center">
                                <img src="assets/img/germany-flag.jpg" class="shadow-sm" alt="flag">
                                <span>Ger</span>
                            </a>
                            <a href="#" class="dropdown-item d-flex align-items-center">
                                <img src="assets/img/france-flag.jpg" class="shadow-sm" alt="flag">
                                <span>Fre</span>
                            </a>
                            <a href="#" class="dropdown-item d-flex align-items-center">
                                <img src="assets/img/spain-flag.jpg" class="shadow-sm" alt="flag">
                                <span>Spa</span>
                            </a>
                            <a href="#" class="dropdown-item d-flex align-items-center">
                                <img src="assets/img/russia-flag.jpg" class="shadow-sm" alt="flag">
                                <span>Rus</span>
                            </a>
                            <a href="#" class="dropdown-item d-flex align-items-center">
                                <img src="assets/img/italy-flag.jpg" class="shadow-sm" alt="flag">
                                <span>Ita</span>
                            </a>
                        </div>
                    </div> -->
                    <a routerLink="/cart" class="cart-wrapper-btn d-inline-block">
                        <i class='bx bx-cart-alt'></i>
                        <span>{{cartCount}}</span>
                    </a>
                    <!-- <div class="search-box d-inline-block">
                        <i class='bx bx-search' (click)="toggleClass3()"></i>
                    </div> -->
                </div>
            </nav>
        </div>
    </div>
</header>

<div class="search-overlay" [class.active]="classApplied3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-close" (click)="toggleClass3()">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>
            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search-alt'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>