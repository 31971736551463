<app-header-style-four [cartCount]="cartCount"></app-header-style-four>

<!-- <div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Shop</li>
            </ul>
            <h2>Cart</h2>
        </div>
    </div>
</div> -->



<div class="cart-area ptb-100">
    <div class="container">
        <h4 class="mb-3">Cart</h4>
        <form>
            <div class="cart-table table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Product</th>
                            <th scope="col">Name</th>
                            <th scope="col">Unit Price</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let list of cartList,let i=index" > 
                            <td class="product-thumbnail">
                                <a routerLink="/single-products">
                                    <img src="{{list.url}}" alt="item">
                                </a>
                            </td>
                            <td class="product-name">
                                <a routerLink="/single-products">{{list.name}}</a>
                            </td>
                            <td class="product-price">
                                <span class="unit-amount">${{list.price}}</span>
                            </td>
                            <td class="product-quantity">
                                <span class="unit-amount">{{list.qty}}</span>
                                <!-- <div class="input-counter">
                                    <input type="number" min="1" value="1">
                                </div> -->
                            </td>
                            <td class="product-subtotal">
                                <span class="subtotal-amount">${{list.price*list.qty}}</span>
                                <a (click)="deleteRow(list,i)" class="remove"><i class='bx bx-trash'></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="cart-totals">
                <h3>Cart Totals</h3>
                <ul>
                    <!-- <li>Subtotal <span>${{totAmt.toFixed(2)}}</span></li>
                    <li>Shipping <span>$30.00</span></li> -->
                    <li>Total <span>${{totAmt.toFixed(2)}}</span></li>
                </ul>
                <a routerLink="/checkout" class="default-btn"><i class='bx bx-shopping-bag icon-arrow before'></i><span class="label">Proceed to Checkout</span><i class="bx bx-shopping-bag icon-arrow after"></i></a>
            </div>
        </form>
    </div>
</div>