import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-checkout-page',
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.scss']
})
export class CheckoutPageComponent implements OnInit {
  cartList: any;
  totAmt: number=0;
  rate:any="1"
  cur: any="$";

  constructor(public router:Router) { 
    this.cartList = JSON.parse(localStorage.getItem("item"));
    if(this.cartList)
      {
        for(var i=0;i<this.cartList.length;i++)
          {
            this.totAmt += Number(this.cartList[i]['price']*this.cartList[i]['qty'])
          }
      }
   }

   rateCal(val)
   {
      this.rate=val
      if(val == "1")
        {
          this.cur="$"
        }
      else if(val == "1.36")
        {
          this.cur="CA$"
        }
      else
      {
        this.cur="INR"
      }
   }

   orderConfirm()
   {
    this.router.navigate(['/register'])
    localStorage.clear()
   }
  

  ngOnInit(): void {
  }

}
