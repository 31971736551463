import { Component } from '@angular/core';

@Component({
  selector: 'app-dehydrated-powder',
  templateUrl: './dehydrated-powder.component.html',
  styleUrls: ['./dehydrated-powder.component.scss']
})
export class DehydratedPowderComponent {

}
