import { Component } from '@angular/core';

@Component({
  selector: 'app-veg-pickle',
  templateUrl: './veg-pickle.component.html',
  styleUrls: ['./veg-pickle.component.scss']
})
export class VegPickleComponent {

}
