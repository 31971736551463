import { Component, OnInit, numberAttribute } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-products-details-page',
    templateUrl: './products-details-page.component.html',
    styleUrls: ['./products-details-page.component.scss']
})
export class ProductsDetailsPageComponent implements OnInit {
    name: any;
    price: any;
    qty:number=1
    tempItem: any;
    cartList: any;
    cartCount: any;
    imgUrl: any;

    constructor(public http: HttpClient,private route: ActivatedRoute) {
        this.route.params.subscribe(datas => {
            this.name = datas['name'];
            this.price = datas['price'];
        });
        this.imgUrl = localStorage.getItem("path");
        this.cartList = JSON.parse(localStorage.getItem("item"));
    if (this.cartList) {
      this.cartCount = this.cartList.length
    }
    else {
      this.cartCount = 0
    }
    }

    ngOnInit(): void {
    }

    qtyCal(q,p)
    {
        this.price = Number(q)*Number(p)
    }

    add(i,n,p,q)
    {
      this.tempItem = JSON.parse(localStorage.getItem("item"));
      if(this.tempItem == null)
        {
          this.tempItem =[]
        }
      var cartDet =
      {
        "name":n,
        "url":i,
        "price":p,
        "qty":q
      }
      // this.tempItem =[]
      this.tempItem.push(cartDet)
      localStorage.setItem("item", JSON.stringify(this.tempItem));
      
      this.cartList = JSON.parse(localStorage.getItem("item"));
      this.cartCount = this.cartList.length
    }

    // for tab click event
    currentTab = 'tab1';
    switchTab(event: MouseEvent, tab: string) {
        event.preventDefault();
        this.currentTab = tab;
    }

    detailsImageSlides: OwlOptions = {
        loop: true,
        nav: false,
        dots: false,
        autoplayHoverPause: true,
        autoplay: true,
        margin: 30,
        items: 1,
        navText: [
            "<i class='bx bx-left-arrow-alt'></i>",
            "<i class='bx bx-right-arrow-alt'></i>"
        ]
    }

}
