import { Component, OnInit,Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Output() newItemEvent = new EventEmitter<string>();
  rate:any=1
  
  constructor() {
    this.rate = JSON.parse(localStorage.getItem("rate"));
    if (this.rate == null) {
      localStorage.setItem("rate", "1");
    }
    else {
      this.rate = JSON.parse(localStorage.getItem("rate"));
    }
   }

  ngOnInit(): void {
  }
  addNewItem(value: string) {
    this.newItemEvent.emit(value);
  }

  getAmount(rate)
  {
    localStorage.setItem("rate", rate);
  }

}
