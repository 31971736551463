import { Component } from '@angular/core';

@Component({
  selector: 'app-vadiyalu',
  templateUrl: './vadiyalu.component.html',
  styleUrls: ['./vadiyalu.component.scss']
})
export class VadiyaluComponent {

}
