import { Component } from '@angular/core';

@Component({
  selector: 'app-childhood-memories',
  templateUrl: './childhood-memories.component.html',
  styleUrls: ['./childhood-memories.component.scss']
})
export class ChildhoodMemoriesComponent {

}
