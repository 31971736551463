import { Component } from '@angular/core';

@Component({
  selector: 'app-indian-snacks',
  templateUrl: './indian-snacks.component.html',
  styleUrls: ['./indian-snacks.component.scss']
})
export class IndianSnacksComponent {

}
