<app-header-style-four></app-header-style-four>



<div class="error-404-area">
    <div class="container">
        <div class="notfound">
            <div class="notfound-bg">
                <div></div>
                <div></div>
                <div></div>
            </div>
            <!-- <h1></h1> -->
            <h3>Order Confirmed!</h3>
            <p>Our executive will contact shortly....</p>
            <a routerLink="/" class="default-btn"><i class='bx bx-home-circle icon-arrow before'></i><span class="label">Back Home</span><i class="bx bx-home-circle icon-arrow after"></i></a>
        </div>
    </div>
</div>