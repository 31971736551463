<app-header-style-four [cartCount]="cartCount"></app-header-style-four>
<!-- <app-homesix-main-banner *ngIf="banner"></app-homesix-main-banner> -->
<!-- <div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Shop</li>
            </ul>
            <h2>Shop Grid</h2>
        </div>
    </div>
</div> -->
<!-- <h4>Get your hands on your favourites🔥</h4> -->
<div class="shop-area ptb-100" *ngIf="banner">
    <div class="container">
        <div class="woocommerce-topbar">
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-4">
                    <div class="topbar-result-count">
                        <h3>Get your hands on your favourites🔥</h3><br>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-product-box mb-30">
                    <div class="product-image">
                        <a (click)="refresh('/shop-grid/non-veg-pickles')">
                            <img src="assets/img/product/banner.jpg" alt="image" >
                            <img src="assets/img/product/banner.jpg" alt="image">
                        </a>
                    </div>
                    <div class="product-content">
                        <h6><a (click)="refresh('/shop-grid/non-veg-pickles')">Non Veg Pickle &#8594;</a></h6>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-product-box mb-30">
                    <div class="product-image">
                        <a (click)="refresh('/shop-grid/veg-pickles')">
                            <img src="assets/img/product/banner1.jpg" alt="image" >
                            <img src="assets/img/product/banner1.jpg" alt="image">
                        </a>
                    </div>
                    <div class="product-content">
                        <h6><a (click)="refresh('/shop-grid/veg-pickles')">Veg Pickle &#8594;</a></h6>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-product-box mb-30">
                    <div class="product-image">
                        <a (click)="refresh('/shop-grid/indian-sweets')">
                            <img src="assets/img/product/banner3.jpg" alt="image" >
                            <img src="assets/img/product/banner3.jpg" alt="image">
                        </a>
                    </div>
                    <div class="product-content">
                        <h6><a (click)="refresh('/shop-grid/indian-sweets')">Organic Jaggery &#8594;</a></h6>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-product-box mb-30">
                    <div class="product-image">
                        <a (click)="refresh('/shop-grid/indian-snacks')">
                            <img src="assets/img/product/banner4.jpg" alt="image" >
                            <img src="assets/img/product/banner4.jpg" alt="image">
                        </a>
                    </div>
                    <div class="product-content">
                        <h6><a (click)="refresh('/shop-grid/indian-snacks')">Indian Snacks &#8594;</a></h6>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-product-box mb-30">
                    <div class="product-image">
                        <a (click)="refresh('/shop-grid/indian-snacks')">
                            <img src="assets/img/product/kids/p8.jpg" alt="image" >
                            <img src="assets/img/product/kids/p8.jpg" alt="image">
                        </a>
                    </div>
                    <div class="product-content">
                        <h6><a (click)="refresh('/shop-grid/indian-snacks')">Kids Story Books &#8594;</a></h6>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-product-box mb-30">
                    <div class="product-image">
                        <a (click)="refresh('/shop-grid/indian-snacks')">
                            <img src="assets/img/product/mens/m2.webp" alt="image" >
                            <img src="assets/img/product/mens/m2.webp" alt="image">
                        </a>
                    </div>
                    <div class="product-content">
                        <h6><a (click)="refresh('/shop-grid/indian-snacks')">Mens Collection &#8594;</a></h6>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-product-box mb-30">
                    <div class="product-image">
                        <a (click)="refresh('/shop-grid/indian-snacks')">
                            <img src="assets/img/product/women/b1.jpg" alt="image" >
                            <img src="assets/img/product/women/b1.jpg" alt="image">
                        </a>
                    </div>
                    <div class="product-content">
                        <h6><a (click)="refresh('/shop-grid/indian-snacks')">Womens Collection &#8594;</a></h6>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-product-box mb-30">
                    <div class="product-image">
                        <a (click)="refresh('/shop-grid/indian-snacks')">
                            <img src="assets/img/product/kid_wear/g29.webp" alt="image" >
                            <img src="assets/img/product/kid_wear/g29.webp" alt="image">
                        </a>
                    </div>
                    <div class="product-content">
                        <h6><a (click)="refresh('/shop-grid/indian-snacks')">Kids Collection &#8594;</a></h6>
                    </div>
                </div>
            </div>
        </div>
        <div style="text-align: center;"><button class="btn btn-dark">View all</button></div>
    </div>
</div>


<div class="shop-area ptb-100" *ngIf="!banner">
    <div class="container">
        <div class="woocommerce-topbar">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-4">
                    <div class="topbar-result-count">
                        <h3 style="text-transform: uppercase;">{{product}}</h3><br>
                        <p>Showing 1 - 6 of 54</p>
                    </div>
                </div>
                <div class="col-lg-8 col-md-8">
                    <div class="topbar-ordering-and-search">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-md-5 offset-lg-4 offset-md-1 col-sm-6">
                                <div class="topbar-ordering">
                                    <select class="form-select">
                                        <option>Sort by popularity</option>
                                        <option>Sort by latest</option>
                                        <option>Default sorting</option>
                                        <option>Sort by rating</option>
                                        <option>Sort by new</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6 col-sm-6">
                                <div class="topbar-search">
                                    <form>
                                        <label><i class="bx bx-search"></i></label>
                                        <input type="text" class="input-search" placeholder="Search here...">
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6" *ngFor="let product_List of product_List" >
                <div class="single-product-box mb-30" style="border: 1px solid #000;box-shadow: 2px 2px;">
                    <div class="product-image">
                        <a (click)="setImg(product_List.image)" routerLink="/single-products/{{product_List.name}}/{{product_List.regular_price}}">
                            <img src="{{product_List.image}}" alt="image" style="min-height: 310px; max-height: 310px;">
                            <img src="{{product_List.image}}" alt="image">
                        </a>
                        <a (click)="add(product_List.image,product_List.name,(product_List.regular_price*rate).toFixed(2),1)" 
                            [ngClass]="addStatus == true ? 'add-to-cart-btn' : 'btn-added'"
                            class="add-to-cart-btn">
                            <span *ngIf="addStatus">Add To Cart</span><i *ngIf="addStatus" class='bx bx-cart'></i>
                            <span *ngIf="!addStatus">Added</span><i *ngIf="!addStatus" class='bx bx-plus'></i>
                        </a>
                    </div>
                    <div class="product-content" (click)="setImg(product_List.image)">
                        <h6><a routerLink="/single-products">{{product_List.name}}</a></h6>
                        <div class="price">
                            <span class="new"><span style="color: red;">US$</span> {{product_List.regular_price}}&nbsp; |
                            <span style="color: red;">CA$</span> {{(product_List.regular_price*1.36).toFixed(0)}}&nbsp; |
                            <span style="color: red;">INR</span> {{(product_List.regular_price*83.24).toFixed(0)}}</span>
                        </div>
                        <!-- <div>
                            <button class="btn btn-primary btn-block btn-outline">{{product_List.availability}}</button>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer (newItemEvent)="addItem($event)"></app-footer>