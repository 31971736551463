import { Component } from '@angular/core';

@Component({
  selector: 'app-non-veg-pickle',
  templateUrl: './non-veg-pickle.component.html',
  styleUrls: ['./non-veg-pickle.component.scss']
})
export class NonVegPickleComponent {

}
