import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cart-page',
  templateUrl: './cart-page.component.html',
  styleUrls: ['./cart-page.component.scss']
})
export class CartPageComponent implements OnInit {
  cartList: any;
  totAmt:any=0
  cartCount: any;

  constructor() {
    this.cartList = JSON.parse(localStorage.getItem("item"));
    if(this.cartList)
      {
        for(var i=0;i<this.cartList.length;i++)
          {
            this.totAmt += Number(this.cartList[i]['price']*this.cartList[i]['qty'])
          }
      }
    if (this.cartList) {
      this.cartCount = this.cartList.length
    }
    else {
      this.cartCount = 0
    }
   }
   deleteRow(list,j)
   {
    this.cartList.splice(j,1)
    localStorage.setItem("item", JSON.stringify(this.cartList));
    this.cartList = JSON.parse(localStorage.getItem("item"));
    if (this.cartList) {
      this.cartCount = this.cartList.length
      this.totAmt=0
      for(var i=0;i<this.cartList.length;i++)
        {
          this.totAmt += Number(this.cartList[i]['price']*this.cartList[i]['qty'])
        }
    }
    else {
      this.cartCount = 0
    }
   }

  ngOnInit(): void {
  }

}
