import { Component } from '@angular/core';

@Component({
  selector: 'app-masala',
  templateUrl: './masala.component.html',
  styleUrls: ['./masala.component.scss']
})
export class MasalaComponent {

}
