import { Component } from '@angular/core';

@Component({
  selector: 'app-kondapalli-toys',
  templateUrl: './kondapalli-toys.component.html',
  styleUrls: ['./kondapalli-toys.component.scss']
})
export class KondapalliToysComponent {

}
