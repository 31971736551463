import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-shop-grid-page',
  templateUrl: './shop-grid-page.component.html',
  styleUrls: ['./shop-grid-page.component.scss']
})
export class ShopGridPageComponent implements OnInit {
  product_List: any;
  root: any;
  banner: boolean;
  product: any;
  tempItem: any = [];
  cartCount: number = 0
  cartList: any;
  rate: any = 1;
  addStatus: boolean=true;

  constructor(public http: HttpClient, private route: ActivatedRoute, public router: Router) {
    this.rate = JSON.parse(localStorage.getItem("rate"));
    if (this.rate == null) {
      localStorage.setItem("rate", "1");
    }
    else {
      this.rate = JSON.parse(localStorage.getItem("rate"));
    }
    this.cartList = JSON.parse(localStorage.getItem("item"));
    if (this.cartList) {
      this.cartCount = this.cartList.length
    }
    else {
      this.cartCount = 0
    }
    this.route.params.subscribe(datas => {
      this.root = datas['id'];
      this.product = this.root
      if (this.root == "all") {
        this.banner = true
      }
      else {
        http.get('assets/' + this.root + '.json').subscribe(data => {
          this.product_List = data
        });
      }
    });
  }
  addItem(newItem: string) {
    this.rate = newItem
  }
  refresh(root) {

    this.router.navigate([root])
    setTimeout(() => {
      location.reload()
    }, 500);

  }
  setImg(i) {
    localStorage.setItem("path", i);
  }
  add(i, n, p, q) {
    this.addStatus = false
    setTimeout(() => {
      this.addStatus = true
    }, 1000);
    this.tempItem = JSON.parse(localStorage.getItem("item"));
    // console.log(this.tempItem)
    if (this.tempItem == null) {
      this.tempItem = []
    }
    var cartDet =
    {
      "name": n,
      "url": i,
      "price": p,
      "qty": q
    }
    // this.tempItem =[]
    this.tempItem.push(cartDet)
    localStorage.setItem("item", JSON.stringify(this.tempItem));

    this.cartList = JSON.parse(localStorage.getItem("item"));
    this.cartCount = this.cartList.length
  }

  ngOnInit(): void {
  }

}
