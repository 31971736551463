import { Component } from '@angular/core';

@Component({
  selector: 'app-niloufer-biscuits',
  templateUrl: './niloufer-biscuits.component.html',
  styleUrls: ['./niloufer-biscuits.component.scss']
})
export class NilouferBiscuitsComponent {

}
