<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Contact Us</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <img src="assets/img/ico/uk.jpg" width="25px">
                            <a href="https://maps.app.goo.gl/eed7FCmXRUjrTNuf6" target="_blank"> &nbsp; 2 Cardinal Gardens, Doncaster DN6 9FN</a>
                        </li>
                        <li>
                            <i class='bx bx-map'></i>
                            <img src="assets/img/ico/us.jpg" width="25px">
                            <a href="https://maps.app.goo.gl/dwYD4EMB2iMVUVc67" target="_blank"> &nbsp;  1822 Morning Brook Drive Forest Hill MD 21050</a>
                        </li>
                        <li>
                            <i class='bx bx-map'></i>
                            <img src="assets/img/ico/ca.jpg" width="25px">
                            <a href="https://maps.app.goo.gl/ibGSJnfSMGH3w78x9" target="_blank"> &nbsp;   2980 Don Mills Road, North York, ON M2J 3B9</a>
                        </li>
                        <li>
                            <i class='bx bx-map'></i>
                            <img src="assets/img/ico/in.jpg" width="25px">
                            <a href="https://maps.app.goo.gl/ibGSJnfSMGH3w78x9" target="_blank"> </a>
                        </li>
                        <!-- <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:+11234567890"></a>
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <a href="mailto:hello@ednuv.com">info@paravglobal.com</a>
                        </li> -->
                    </ul>
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                    </ul>
                    <!-- <ul class="social-link">
                        <li>
                            <label class="text-white mb-3">Country / Region</label>
                            <select class="form-select" (change)="getAmount(rate);addNewItem(rate)" [(ngModel)]="rate">
                                <option value="1">United States (USD $)</option>
                                <option value="1.36">Canada (USD $)</option>
                                <option value="1.35">Singapore (USD $)</option>
                            </select>
                        </li>
                    </ul> -->
                </div>
            </div>
            <!-- <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Support</h3>
                    <ul class="support-link">
                        <li><a routerLink="/">Privacy</a></li>
                        <li><a routerLink="/">FAQ's</a></li>
                        <li><a routerLink="/">Support</a></li>
                        <li><a routerLink="/">Terms</a></li>
                        <li><a routerLink="/">Condition</a></li>
                        <li><a routerLink="/">Policy</a></li>
                    </ul>
                </div>
            </div> -->
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Quick Link</h3>
                    <ul class="useful-link">
                        <li><a routerLink="/">About Us</a></li>
                        <li><a routerLink="/">Privacy Policy</a></li>
                        <li><a routerLink="/">Refund Policy</a></li>
                        <li><a routerLink="/">Shipping Policy</a></li>
                        <li><a routerLink="/">Terms & Condition</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Newsletter</h3>
                    <div class="newsletter-box">
                        <p>To get the latest news and latest updates from us.</p>
                        <form class="newsletter-form">
                            <label>Your e-mail address:</label>
                            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                            <button type="submit">Subscribe</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="footer-bottom-area">
        <div class="container">
            <div class="logo">
                <a routerLink="/" class="d-inline-block"><img src="assets/img/logo.png" alt="image"></a>
            </div>
            <p>© Ednuv is Proudly Owned by <a href="https://envytheme.com/" target="_blank">paravgloba</a></p>
        </div>
    </div> -->
</footer>

<ngx-scrolltop></ngx-scrolltop>