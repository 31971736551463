import { Component } from '@angular/core';

@Component({
  selector: 'app-pooja-items',
  templateUrl: './pooja-items.component.html',
  styleUrls: ['./pooja-items.component.scss']
})
export class PoojaItemsComponent {

}
