import { Component } from '@angular/core';

@Component({
  selector: 'app-indian-groceries',
  templateUrl: './indian-groceries.component.html',
  styleUrls: ['./indian-groceries.component.scss']
})
export class IndianGroceriesComponent {

}
